<template>
  <form @submit.prevent="pushToSearch">
    <div class="flex align-items-center">
      <div class="mr-2 pt-2">
        <BaseTextField
          v-model="values.search"
          placeholder="uc.browseHeader.search"
          :maxLength="null"
        />
      </div>
      <div class="mr-2">
        <BaseButton type="submit" icon="pi pi-search" />
      </div>
      <div v-if="values.search">
        <BaseButton @click="clear" icon="pi pi-times" text />
      </div>
    </div>
  </form>
</template>

<script>
import { reactive } from "vue";
import { useRoute, useRouter } from "vue-router";

export default {
  setup() {
    const router = useRouter();
    const route = useRoute();

    const routeName = "Courses";

    const values = reactive({
      search: route.query.search,
    });

    const pushQuery = async (query) => {
      try {
        await router.push({
          name: routeName,
          query: query,
        });
      } catch (error) {
        return;
      }
    };

    const pushToSearch = async () => {
      const query = {};

      if (route.query.courseTopicId) {
        query.courseTopicId = route.query.courseTopicId;
      }

      if (values.search) {
        query.search = values.search;
      }

      await pushQuery(query);
    };

    const clear = async () => {
      values.search = null;

      if (route.name !== routeName) {
        return;
      }

      const query = {};

      if (route.query.courseTopicId) {
        query.courseTopicId = route.query.courseTopicId;
      }

      await pushQuery(query);
    };

    return {
      values,
      pushToSearch,
      clear,
    };
  },
};
</script>
