<template>
  <HAWHome>
    <HAWPageContent :breadcrumb="false" v-if="user">
      <template #header>
        <SearchField />
      </template>
      <template #default>
        <router-view />
      </template>
    </HAWPageContent>
    <HAWPageContent :breadcrumb="false" :context-menu="false" v-else>
      <template #header>
        <SearchField />
      </template>
      <template #context>
        <div class="flex">
          <BaseButton
            v-if="showRegister"
            @click="goToRegister"
            label="uc.startpage.register"
            small
          />
          <BaseButton
            v-if="showRegisterTeacher"
            @click="goToTeacherRegister"
            label="stp.teacher.startpage.btn.label.register"
            class="ml-3"
            small
          />
          <BaseButton
            v-if="showLogin"
            @click="goToLogin"
            label="uc.startpage.login"
            small
            outlined
            class="ml-3"
          />
        </div>
      </template>
      <template #default>
        <router-view />
      </template>
    </HAWPageContent>
  </HAWHome>
</template>

<script>
import HAWPageContent from "@/modules/haw-components-hac/Base/HAWPageContent";
import { useStore } from "vuex";
import HAWHome from "@/modules/haw-components-hac/Shared/HAWHome";
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import SearchField from "./partials/SearchField";

export default {
  components: { SearchField, HAWHome, HAWPageContent },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const user = computed(() => {
      return store.getters["usm/authUser/user"];
    });

    const showLogin = computed(() => {
      return route.name !== "Login";
    });

    const showRegister = computed(() => {
      return (
        route.name !== "TeacherStartPage" &&
        route.name !== "Register" &&
        route.name !== "TeacherRegister"
      );
    });

    const showRegisterTeacher = computed(() => {
      return route.name === "TeacherStartPage";
    });

    const goTo = async (name) => {
      try {
        await router.push({ name });
      } catch {
        return;
      }
    };

    const goToRegister = async () => {
      await goTo("Register");
    };

    const goToTeacherRegister = async () => {
      await goTo("TeacherRegister");
    };

    const goToLogin = async () => {
      await goTo("Login");
    };

    return {
      user,
      goToRegister,
      goToTeacherRegister,
      goToLogin,
      showLogin,
      showRegister,
      showRegisterTeacher,
    };
  },
};
</script>
